// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serviceUrl: 'http://localhost:3005/',
  serviceUrl: 'https://8mqpob6q1f.execute-api.ap-south-1.amazonaws.com/qa/',
  imageUploadServiceUrl: 'https://0ca0jplvjj.execute-api.ap-south-1.amazonaws.com/qa/',
  categoryImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/category/',
  bannerImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/banner/',
  stripBannerImageUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/stripbanner/',
  smallBannerImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/smallbanner/',
  promotionBannerImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/promotionbanner/',
  headerImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/header/',
  footerImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/footer/',
  bussinessuserImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/businessuser/',
  companyuserImageServiceUrl: 'https://international-listing-images.s3.ap-south-1.amazonaws.com/images/companyuser/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
