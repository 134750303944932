import { Component, OnInit } from '@angular/core';
import { SubscribeUserService } from '../subscribe-user/subscribe-user.service';
import { SharedService } from '../shared.service';
import { AppSetting } from 'src/app/config/appSetting';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerModel: any;
  footerValue: any;
  footerImageServiceUrl = AppSetting.footerImageServiceUrl;
  currentYear = new Date().getFullYear();
  constructor(private subScribeUserService: SubscribeUserService, private sharedService: SharedService) { }

  ngOnInit() {
    this.getfooter();
  }
  getSubscrbe() {
    this.subScribeUserService.openCustomer()
      .subscribe(res => {
        if (res) {
        }
      });
  }
  getfooter() {
    this.sharedService.getFooter().subscribe(data => {
      this.footerModel = data;
    }, error => {
      console.log(error);
    });
  }

}
