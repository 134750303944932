import { environment } from 'src/environments/environment';

export const AppSetting: AppSettingType = {
  serviceUrl: environment.serviceUrl,
  imageUploadServiceUrl: environment.imageUploadServiceUrl,
  categoryImageServiceUrl: environment.categoryImageServiceUrl,
  bannerImageServiceUrl: environment.bannerImageServiceUrl,
  stripBannerImageUrl: environment.stripBannerImageUrl,
  smallBannerImageServiceUrl: environment.smallBannerImageServiceUrl,
  promotionBannerImageServiceUrl: environment.promotionBannerImageServiceUrl,
  headerImageServiceUrl: environment.headerImageServiceUrl,
  footerImageServiceUrl: environment.footerImageServiceUrl,
  bussinessuserImageServiceUrl: environment.bussinessuserImageServiceUrl,
  companyuserImageServiceUrl: environment.companyuserImageServiceUrl
    /* serviceUrl: 'http://internationalstandardlisting.com/listingservice/' */
};
