import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../shared.service';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(protected router: Router, private sharedService: SharedService) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (route.queryParamMap.get('code')) {
            const collection = { centerCode: route.queryParamMap.get('code') };
            return (this.sharedService.getUserDetailByCenterCode(collection)).pipe(map(a => {
                if (a) {
                    this.sharedService.setCenterCode(a);
                    return true;
                } else {
                    return false;
                }
              }))
        } else {
            return true;
        }
    }

  
}