import { EventEmitter, Injectable } from '@angular/core';
import { AppSetting } from '../config/appSetting';
import { Header } from './navheader/header.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Footer } from './footer/footer.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  serviceUrl: string = AppSetting.serviceUrl;
  // public trigger_centerCode = new Subject();
  centerCode: any;
  public trigger_centerCode: EventEmitter<any> = new EventEmitter();
  paymentOption: any;

  constructor(private http: HttpClient) { }
  setCenterCode(data) {
    this.centerCode = data._id;
    this.paymentOption = data.paymentOptions; 
  }
  getCenterCode() {
    return {centerCode: this.centerCode, option: this.paymentOption};
  }
  getHeader(): Observable<any> {
    const sharedUrl = 'getheader';
    const url: string = this.serviceUrl + sharedUrl;
    return this.http.get<Header>(url);
  }

  customerSubscribe(data): Observable<any> {
    const sharedUrl = 'makesubscribe';
    const url: string = this.serviceUrl + sharedUrl;
    return this.http.post<Header>(url, data);
  }
  getLogin() {
    return sessionStorage.getItem('businessLogIn');
  }
  getSubscribe() {
    return sessionStorage.getItem('subscribe');
  }
  getUserId() {
    return sessionStorage.getItem('userID');
  }
  getFooter(): Observable<any> {
    const sharedUrl = 'getfooter';
    const url: string = this.serviceUrl + sharedUrl;
    return this.http.get<Footer>(url);
  }
  getUserDetailByCenterCode(data): Observable<any> {
    const listUrl = 'getuserdetailbycentercode';
    const url: string = this.serviceUrl + listUrl;
    return this.http.post<any>(url, data);
  }
}
